.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.pageButton {
    margin: 0 5px;
    padding: 10px 15px;
    border: 1px solid #007bff;
    background-color: white;
    color: #007bff;
    cursor: pointer;
    border-radius: 4px;

    &.active {
        background-color: #007bff;
        color: white;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
