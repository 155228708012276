.pending {
    background-color: orange;
    color: white;
    height: 25px;
    border-radius: 15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accept {
    background-color: green;
    color: white;
    height: 25px;
    border-radius: 15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rejected {
    background-color: orangered;
    color: white;
    height: 25px;
    border-radius: 15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusCont {
    display: flex;
}
