.Header {
    .navbar {
        background: #fff !important;
        border-bottom: 1px solid #e8e9ed;
        // margin-bottom: 10px;
    }

    .logo {
        font-size: 24px;
        font-weight: 700;
        color: #fff;
    }
}
