.photo {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.container {
    width: 100%; // Убедитесь, что родительский контейнер также занимает 100% ширины
    overflow: auto; // Чтобы управлять прокруткой, если это необходимо
}

.basic {
    background-color: silver;
    color: white;
    border-radius: 15px;
    text-align: center;
}

.pro {
    background-color: green;
    color: white;
    border-radius: 15px;
    text-align: center;
}

.countUser {
    margin-top: 13px;
    font-size: 20px;
}

.pageContainer {
    display: flex;
    margin-top: 10px;
    button {
        margin-left: 20px;
    }
}

.checkbox {
    margin-top: 10px;
    margin-left: 10px;
}

.fixedRow {
    height: 100px;
}

.photoImage {
    max-width: 200px; // Установите максимальную ширину для изображения
    max-height: 200px; // Установите максимальную высоту для изображения
    border-radius: 8px; // Закругляем углы
}

.noPhoto {
    width: 200px; // Установите фиксированную ширину
    height: 200px; // Установите фиксированную высоту
    background-color: lightgray; // Серый фон
    color: white; // Цвет текста
    display: flex;
    justify-content: center; // Центрируем текст по горизонтали
    align-items: center; // Центрируем текст по вертикали
    border-radius: 8px; // Закругляем углы
    font-size: 16px; // Размер шрифта
}

.profileContainer {
    margin-top: 20px;
}

.profilePhoto {
    margin-bottom: 20px;
    width: 200px;
    align-items: center;
}

.photoImage {
    max-width: 200px; // Установите максимальную ширину для изображения
    max-height: 200px; // Установите максимальную высоту для изображения
    border-radius: 8px; // Закругляем углы
}

.noPhoto {
    width: 200px; // Установите фиксированную ширину
    height: 200px; // Установите фиксированную высоту
    background-color: lightgray; // Серый фон
    color: white; // Цвет текста
    display: flex;
    justify-content: center; // Центрируем текст по горизонтали
    align-items: center; // Центрируем текст по вертикали
    border-radius: 8px; // Закругляем углы
    font-size: 16px; // Размер шрифта
}

.profileField {
    margin-bottom: 16px; // Отступ между полями
    font-size: 16px; // Размер шрифта
    color: #333; // Цвет текста
    strong {
        margin-bottom: 10px;
    }
}

.profileField strong {
    display: inline-block; // Чтобы выделить подпись
    width: 150px; // Устанавливаем фиксированную ширину для всех подписей
    color: #555; // Более темный цвет для подписей
}
