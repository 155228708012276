.articleBox {
    position: relative;
}

.checkBox {
    position: relative;
    top: 4px;
    margin-right: 8px;
    margin-left: 5px;
}
