.container div {
    white-space: pre-wrap;
}

.checkBox {
    position: relative;
    top: 4px;
    margin-right: 8px;
    margin-left: 5px;
}

.photo {
    width: 100px; /* Ширина фото, измените по необходимости */
    height: 100px; /* Высота фото, измените по необходимости */
    background-size: cover; /* Обеспечивает, что изображение будет масштабироваться */
    background-position: center; /* Центрирование изображения */
    border-radius: 10px; /* Скругление углов */
}

.scrollableCell {
    overflow: auto;
    white-space: pre-wrap; /* чтобы текст переносился на новую строку, если слишком длинный */
    max-height: 100px; /* задайте максимальную высоту для включения прокрутки */
}

.customTextarea {
    max-height: 120px; /* Максимальная высота */
    overflow-y: auto; /* Включить вертикальную прокрутку */
    padding: 10px; /* Отступы для эстетики */
    border: 1px solid #d9d9d9; /* Цвет рамки */
    border-radius: 4px; /* Закругленные углы */
    background-color: #f5f5f5; /* Светлый фон */
    font-family: inherit; /* Унаследовать шрифты */
    font-size: 14px; /* Размер шрифта */
    resize: none; /* Запретить изменение размера */

    /* Стили для полосы прокрутки */
    &::-webkit-scrollbar {
        width: 10px; /* Ширина полосы прокрутки */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888; /* Цвет ползунка полосы прокрутки */
        border-radius: 5px; /* Закругленные углы ползунка */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Цвет ползунка при наведении */
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0; /* Фон дорожки полосы прокрутки */
        border-radius: 5px; /* Закругленные углы дорожки */
    }
}
