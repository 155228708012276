.PreLoader {
  position: fixed;
  width: 64px;
  height: 64px;
  z-index: 100;
  right: 0;
  left: 0;
  margin: auto;
  top: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.thin {
    background-image: url("../../../assets/images/svg/thin-spinner.svg");
  }

  &.thick {
    background-image: url("../../../assets/images/svg/thick-spinner.svg");
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
