.TextEditor {
    position: relative;

    .area {
        max-height: 80vh;
        min-height: 80vh;
        overflow: auto;
        border-radius: 0 0 5px 5px;

        & > div {
            max-width: 660px;
            font-family: HelveticaNeueCyr;
            font-size: 15px !important;
            line-height: 23px;
            outline: none;
            padding: 12px 15px;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: Gilroy;
            font-weight: normal !important;
            font-style: normal !important;
            color: #666666;
        }

        h2 {
            font-style: normal;
            font-weight: 300;
            font-size: 30px !important;
            line-height: 36px;
            color: #333333;
            margin: 30px 0 25px;
        }

        h3 {
            font-family: Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 20px !important;
            line-height: 23px;
            color: #333333;
            margin: 30px 0 25px;
        }

        p {
            font-family: HelveticaNeueCyr;
            font-style: normal;
            font-weight: normal;
            font-size: 17px !important;
            line-height: 28px;
            color: #666666;
            margin: 25px 0;
        }

        img {
            width: 100%;
            margin: 30px 0;
            border-radius: 10px 10px 0px 0px;
            object-fit: cover;
            max-height: 245px;
        }

        ul,
        ol {
            list-style-position: inside;
            padding: 0;
            margin: 25px 0;
            font-size: 17px;
            line-height: 28px;

            li {
                padding-left: 0 !important;

                &:first-line {
                    text-indent: 20px;
                }

                &:before {
                    content: unset;
                    list-style-type: disc;
                }
            }
        }
    }
}
