.body {
    margin-top: 10px;
    width: 220px;
}

.emptyPhoto {
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    border: 1px dashed #ccc;
    border-radius: 4px;
    font-size: 14px;
}
