.body {
    margin-bottom: 200px;
}

.notes {
    margin-bottom: 10px;
    border-radius: 5px;
}

.noteWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.deleteNoteWrapper {
    position: absolute;
    right: -40px;
    // width: 30px;
}

