.boxUploader {
    position: absolute;
    left: 350px;
    top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

ol li {
    font-weight: 600;
    font-size: 16px;

    span {
        color: rgb(185, 53, 53);
    }
}

.linkCont {
    display: flex;
    align-items: center;
    button {
        padding-right: 20px;
        padding-top: 6px;
        padding-bottom: 7px;
        margin-top: 7px;
        margin-left: 10px;
    }
}

.maxWidthCont {
    text-align: center;
    margin-top: 20px;
    input {
        width: 75px;
    }
}
