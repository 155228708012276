.container {
    font-size: 20px;
}

.desc {
    width: 600px;
}

.wellnessDesc {
    margin-left: 10px;
}

.btnCont {
    button {
        margin-right: 10px;
    }

    margin-top: 30px;
    margin-bottom: 200px;
}

.author {
    display: flex;
    margin-top: 30px;
    // align-items: center;
    span {
        font-size: 20px;
    }
}

.wellness {
    display: flex;
    margin-top: 30px;
}

.img {
    width: 300px;
    object-fit: contain;

    img {
        border-radius: 10px;
        width: 100%;
    }
}

.typeBlock {
    display: flex;
}

.typeText {
    margin-right: 10px;
}

.itemStyle {
    // height: 25px;
    // padding: 0 10px;
    border-radius: 15px;
    // margin-right: 10px;
}

.triggerCont {
    div {
        margin-top: 10px;
    }
}

.stepsCont {
    margin-bottom: 10px;
    div {
        margin-top: 10px;
    }
}

.noteCont {
    margin-bottom: 30px;
    margin-top: 20px;
    div {
        margin-top: 10px;
    }
}

.duration {
    font-size: 20px;
    margin-right: 20px;
}
