.Confirm {
    margin-top: 250px!important;

    .title {
    }

    .message {
        display: flex;
        align-items: center;
        justify-content: center;

        .remind {
            color: #ffb300;
            font-size: 24px;
            margin-right: 10px;
        }
    }
}
