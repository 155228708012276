.pending {
    background-color: orange;
    color: white;
    height: 25px;
    border-radius: 15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accept {
    background-color: green;
    color: white;
    height: 25px;
    border-radius: 15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rejected {
    background-color: orangered;
    color: white;
    height: 25px;
    border-radius: 15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusCont {
    display: flex;
}

.leftAligned {
    text-align: left; /* Выравнивание по левому краю */
    display: flex; /* Добавление flex для более точного контроля */
    justify-content: flex-start; /* Убедитесь, что контент выровнен влево */
    button {
        margin: 0;
        padding: 0;
    }
}

.tableContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tableWrapper {
    position: relative;
    width: 100%;
    flex: 1;
    overflow: hidden;
}

:global {
    .ant-table-wrapper {
        height: 100%;
    }

    .ant-spin-nested-loading {
        height: 100%;
    }

    .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .ant-table {
        overflow: auto;
        
        &-header {
            overflow: hidden !important;
            position: sticky;
            top: 0;
            z-index: 1;
        }
        
        &-body {
            overflow: auto !important;
        }
    }
}
